import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import "./App.css";
import Convertor from "./pages/Convertor";
import Login from "./pages/Login";
import ProtectedRoute from "./utils/protectedRoute";

const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Login /> },
    {
      path: "/convert",
      element: <ProtectedRoute element={<Convertor />} />,
    },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};
export default AppWrapper;
