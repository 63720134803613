import { useNavigate } from "react-router-dom";
import "./style.css";
import { Button, Col, Form, Input, message, Row } from "antd";
import { login } from "../utils/apis";
import { useState } from "react";

const Login = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleLogin = async (values: any) => {
    const { email, password } = values;
    setLoading(true);
    const result = await login(email, password);

    if (result) {
      message.success("Connexion résussie !");
      navigate("/convert");
    } else {
      message.error("Email ou mot de passe incorrect");
    }

    setLoading(false);
  };

  const onFinish = (values: any) => {
    handleLogin(values);
  };
  return (
    <div className='bank--login-container'>
      <div className='bank--login-header'>
        <h2>Bienvenue!</h2>
        <span>Connectez-vous pour accéder à votre compte</span>
      </div>
      <div className='bank--login-form'>
        <h3>Se connecter</h3>
        <Form layout='vertical' autoComplete='off' onFinish={onFinish}>
          <Row gutter={16}>
            <Col xxl={24} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Form.Item
                name='email'
                label='Addresse Email'
                rules={[{ required: true, message: "Merci d'entrer votre email" }]}
                labelCol={{ className: "bank--label-style" }}>
                <Input type='text' placeholder='Addresse email' className='bank--input-style' />
              </Form.Item>
            </Col>

            <Col xxl={24} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Form.Item
                name='password'
                label='Mot de passe'
                labelCol={{ className: "bank--label-style" }}
                rules={[
                  {
                    required: true,
                    message: "Merci d'entrer votre mot de passe",
                  },
                ]}>
                <Input.Password placeholder='Mot de passe' className='bank--input-style' />
              </Form.Item>
            </Col>
          </Row>

          <Col xxl={24} xl={24} lg={12} md={12} sm={12} xs={12}>
            <Form.Item>
              <Button type='primary' htmlType='submit' className='login-button'>
                {loading ? <span className='loader'></span> : "Connexion"}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
    </div>
  );
};

export default Login;
