import React from "react";
import { Navigate } from "react-router-dom";
import cookies from "react-cookies";

const ProtectedRoute = ({ element }) => {
  const cookiesToken = cookies.load("token");

  return cookiesToken ? element : <Navigate to="/" replace />;
};

export default ProtectedRoute;
