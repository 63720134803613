import axios from "axios";
import cookies from "react-cookies";
import { baseUrlApi } from "../proxy";

/** Login user */
export const login = async (email, password) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const { data } = await axios.post(
      `${baseUrlApi}login`,
      {
        email,
        password,
      },
      config
    );
    if (data.success) {
      cookies.save("token", data.success.token);
      return true;
    }

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};
